<div class="main">
  <header class="header">
    <ul class="menu" #menu>
      <li class="menu__item">
        <a class="menu__link">Home</a>
      </li>
      <li class="menu__item">
        <a class="menu__link">About Us</a>
      </li>
      <li class="menu__item">
        <a class="menu__link">How it Works</a>
      </li>
      <li class="menu__item">
        <a class="menu__link">Benefits</a>
      </li>
      <li class="menu__item">
        <a class="menu__link">Testimonials</a>
      </li>
    </ul>
    <ul class="menu menu-right" #menuSecond>
      <li class="menu__item">
        <a class="menu__link">Blog</a>
      </li>
      <li class="menu__item">
        <a class="menu__link">FAQ</a>
      </li>
    </ul>
  </header>
  <section class="first-section">
    <div class="info">
      <h1 class="heading-1">
        Real estate advice for real locals.
      </h1>
      <p class="paragraph">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia adipisci
        deserunt eaque.
      </p>
      <a class="btn btn--start">Get Started</a>
    </div>
    <div class="visuals">
      <div class="image-container">
        <img
          #imageFirst
          src="../assets/photo-1536179247184-595913ca4a28.jpeg"
          alt=""
        />
      </div>
      <div class="image-container">
        <img
          #imageSecond
          src="../assets/photo-1469817805249-72b7df1c3c05.jpeg"
          alt=""
        />
      </div>
    </div>
  </section>
  <section class="second-section" #secondSection>
    <div class="box"></div>
    <h4 class="heading-4" id="about">About Us</h4>
    <h1 class="heading-1" id="buy">Buying a home doesn't have to be a mystery.</h1>
    <div class="info-1">
      <div class="info-1__text">
        <span class="quote">
          "
        </span>
        <h2 class="heading-3">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit.
        </h2>
        <p class="paragraph">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum labore
          tempora eveniet adipisci sunt harum, eos nobis asperiores cumque
          veniam fuga fugit eius ab reiciendis, culpa id recusandae illum
          quisquam!
        </p>
        <a class="btn--learn">Learn More</a>
      </div>
      <div class="info-1__visual">
        <img src="../assets/photo-1593016605825-8cfb409ca3b1.jpeg" alt="">
      </div>
    </div>

  </section>
</div>
